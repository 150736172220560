
.dropdown-left-manual {
    right: 0;
    left: auto;
    padding-left: 1px;
    padding-right: 1px;
    top: 35px
}

.Header {
    // Add your custom styles here
    .avatar {
        background-color: #2c3e50 !important;
    }
    
}

.md-avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 6px 12px !important;
    clear: both;
    font-weight: 400;
    color: #4a341c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.dropdown-item:hover {
    background-color:#DAF3FD
}
.navbar-logo {
    box-sizing: border-box;
    padding-right: 1.5%;
    border-right: 1px solid #9B8AA9;
}

.navbar-style {
    background-color: #00213E;
    height: 55px;
}

.search-icon-style {
    color: white;
    min-width: 19.38px;
    min-height: 19.38px;
}

.notification-icon-style {
    color: white;
    min-width: 19.38px;
    min-height: 19.38px;
}

.navbar-nav .nav-item a {
    border-top: 4px solid #00000000;
    border-bottom: 4px solid #00000000;
}

.navbar-nav .nav-item a.active {
    border-bottom: 4px solid #06ACEE;
}

.nav-item a .icon {
    font-size: 1.4em;
}
