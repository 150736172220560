@import '../../assets/styles/mixins';

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf');
}

body {
    font-size: 0.875rem;
    min-width: 768px;
    font-family: 'Inter', 'Arial', sans-serif !important;
    background-color: #f7f6f9 !important;
    letter-spacing: 0.2px;
}

#root {
    min-height: 100vh;

    .mh-100 {
        min-height: 100vh;
    }
}

input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

input[type='radio'] {
    cursor: pointer;
}

.btn.btn-primary,
.page-item.active .page-link {
    background-color: #06acee !important;
    border-color: #06acee !important;
    color: #fff !important;

    &:hover {
        background-color: #3d91d1 !important;
        border-color: #3d91d1 !important;
    }
}
li:not(.active):not(.disabled) {
    .page-link.btn.btn-light {
        color: #06acee !important;
        background-color: #fff !important;
    }
}
select option:hover {
    color: #fff !important;
    background-color: red !important;
}

select:focus > option:hover {
    color: #fff !important;
    background-color: red !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff !important;
    border-color: #06acee !important;
    background-color: #06acee !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: #3d91d1 !important;
}

.progress-bar {
    background-color: #06acee !important;
}

input[type='range'] {
    accent-color: #42a7f4;
    filter: hue-rotate(186deg) contrast(34%) invert(1) saturate(313%)
        brightness(126%);
}

a,
a.text-primary,
i .text-primary {
    color: #06acee !important;

    &:hover {
        color: #3d91d1 !important;
    }

    &.nav-link {
        color: #ffffff88 !important;
    }

    &.nav-link.active {
        color: #ffffff !important;
    }

    &.dropdown-item {
        color: #4a341c !important;
    }

    &.dropdown-item:focus {
        background-color: #06acee !important;
        color: #fff !important;
    }
}
a.disabled {
    color: #adb5bd !important;
    pointer-events: none !important;
    background-color: transparent !important;
}

//Mui dropdown
.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: #06acee !important;
    color: #fff !important;
}
.MuiButtonBase-root.MuiMenuItem-root:hover:not(.Mui-selected) {
    background-color: #daf3fd !important;
    border-radius: 4px;
}

.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input:hover ~ fieldset,
.MuiInputBase-root:hover:not(.Mui-focused) fieldset {
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.MuiFormControl-root {
    fieldset {
        top: 0px !important;
        legend {
            display: none !important;
        }
    }
}

// Mui Autocomplete
body .MuiAutocomplete-option.MuiBox-root.Mui-focused {
    background-color: #daf3fd;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #06acee !important;
}
.MuiAutocomplete-root {
    fieldset {
        legend {
            display: none !important;
        }
    }
}

.MuiCheckbox-root.Mui-checked {
    color: #6c757d !important;
}
.Mui-checked.MuiRadio-root {
    color: #6c757d !important;
}

.MuiStepper-root {
    .MuiStepLabel-iconContainer {
        .MuiSvgIcon-root {
            font-size: 35px !important;

            &.active,
            &.Mui-active,
            &.Mui-completed {
                color: #06acee !important;
            }
        }
    }

    .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
            font-size: 16px !important;
            margin-left: 5px;
            &.active,
            &.Mui-active,
            .MuiStepLabel-active {
                color: #06acee !important;
            }
        }
    }
}
.my-mui-select {
    .MuiSelect-select {
        padding: 3.5px 0px 7.5px 14px !important;
        margin-top: 5px;
        border-color: #ced4da !important;
    }
    fieldset {
        margin-top: 5px !important;
        legend {
            display: none !important;
        }
    }
    .MuiInputBase-root {
        padding: 0 !important;
    }

    .MuiChip-root {
        height: 25px !important;
    }
    .MuiChip-deleteIcon {
        height: 20px !important;
    }
}

#tags-outlined-listbox li:hover {
    background-color: #daf3fd !important;
}
.my-date-picker {
    .MuiInputBase-input {
        padding: 7.5px 0px 7.5px 14px !important;
    }

    fieldset {
        border-color: #ced4da;
        legend {
            display: none !important;
        }
    }
}

// Scrollbar
* {
    &::-webkit-scrollbar {
        width: 6px !important;
        background-color: #f5f5f5 !important;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 3px !important;
        background-color: #b9b9b9 !important;
    }
}
